(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Explore
     * -- A modal that links to multiple landings
     *
     */

    Vue.component("explore", {
        props: ["i18n", "ignoreResponsiveIcon"],
        data: function () {
            return {
                isModalOpen: false,
                widthScreenPx: 0,
                heightScreenPx: 0,
                headerHeightPx: 0,
                columnMaxWidthPx: 0,
            };
        },
        template: `
 <!-- Search Box -->
<div class="explore">
  <div v-if="!isResponsive || ignoreResponsiveIcon" class="explore__button" @click="onMainButtonClick" ref="modalButton">{{i18n.txtExplore}}<img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/dropdown_green.svg"></div>
  <div v-else class="explore__button-small" @click="onMainButtonClick"><span class="explore__button-small-icon"></span></div>
  
  <div v-if="isModalOpen" class="explore__modal" :style="modalStyleCss" ref="modal">
    <div class="explore__inner">
      <div class="explore__content"><!-- Content -->

        <div class="explore__close">
          <img class="explore__close-icon" @click="close" src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg">
        </div>
        <h1 ref="header" class="explore__head">{{i18n.txtExploreTitle}}</h1> <!-- Head -->
          
        
        <div class="explore__body"> <!-- Body -->
          
          <div class="explore__body-column" ref="column1">
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_spain'});" href="/wikiloc/map.do?sw=26.78%2C-18.93&ne=44.21%2C4.96&page=1&c=ES" class="explore__item" v-html="trailsInText(i18n.txtExploreSpain)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_france'});" href="/wikiloc/map.do?sw=42.5%2C-5&ne=51.15%2C9.56&page=1&c=FR" class="explore__item" v-html="trailsInText(i18n.txtExploreFrance)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_italy'});" href="/wikiloc/map.do?sw=36.62%2C6.75&ne=47.12%2C18.48&page=1&c=IT" class="explore__item" v-html="trailsInText(i18n.txtExploreItaly)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_germany'});" href="/wikiloc/map.do?sw=47.3%2C5.99&ne=54.98%2C15.02&page=1&c=DE" class="explore__item" v-html="trailsInText(i18n.txtExploreGermany)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_ireland'});" href="/wikiloc/map.do?sw=51.67%2C-9.98&ne=55.13%2C-6.03&page=1&c=IE" class="explore__item" v-html="trailsInText(i18n.txtExploreIreland)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_norway'});" href="/wikiloc/map.do?sw=58.08%2C4.99&ne=70.92%2C31.29&page=1&c=NO" class="explore__item" v-html="trailsInText(i18n.txtExploreNorway)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_iceland'});" href="/wikiloc/map.do?sw=63.5%2C-24.33&ne=66.53%2C-13.61&page=1&c=IS" class="explore__item" v-html="trailsInText(i18n.txtExploreIceland)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_morocco'});" href="/wikiloc/map.do?sw=21.42%2C-17.02&ne=35.76%2C-1.12&page=1&c=MA" class="explore__item" v-html="trailsInText(i18n.txtExploreMorocco)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_unitedstates'});" href="/wikiloc/map.do?sw=25%2C-125&ne=49.5%2C-66.96&page=1&c=US" class="explore__item" v-html="trailsInText(i18n.txtExploreUnitedStates)"></a>
          </div>
          
          <div class="explore__body-column" ref="column2">
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_chile'});" href="/wikiloc/map.do?sw=-55.61%2C-75.64&ne=-17.58%2C-66.96&page=1&c=CL" class="explore__item" v-html="trailsInText(i18n.txtExploreChile)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_peru'});" href="/wikiloc/map.do?sw=-18.35%2C-81.41&ne=-0.06%2C-68.67&page=1&c=PE" class="explore__item" v-html="trailsInText(i18n.txtExplorePeru)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_turkey'});" href="/wikiloc/map.do?sw=35.82%2C26.04&ne=42.14%2C44.79&page=1&c=TR" class="explore__item" v-html="trailsInText(i18n.txtExploreTurkey)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_nepal'});" href="/wikiloc/map.do?sw=26.4%2C80.09&ne=30.42%2C88.17&page=1&c=NP" class="explore__item" v-html="trailsInText(i18n.txtExploreNepal)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_vietnam'});" href="/wikiloc/map.do?sw=8.6%2C102.17&ne=23.35%2C109.34&page=1&c=VN" class="explore__item" v-html="trailsInText(i18n.txtExploreVietnam)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_philippines'});" href="/wikiloc/map.do?sw=5.58%2C117.17&ne=18.51%2C126.54&page=1&c=PH" class="explore__item" v-html="trailsInText(i18n.txtExplorePhilippines)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_japan'});" href="/wikiloc/map.do?sw=31.03%2C129.41&ne=45.55%2C145.54&page=1&c=JP" class="explore__item" v-html="trailsInText(i18n.txtExploreJapan)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_australia'});" href="/wikiloc/map.do?sw=-43.63%2C113.34&ne=-10.67%2C153.57&page=1&c=AU" class="explore__item" v-html="trailsInText(i18n.txtExploreAustralia)"></a>
            <a onclick="gtagEvent('goto_map_click', { ref: 'explore_newzealand'});" href="/wikiloc/map.do?sw=-46.64%2C166.51&ne=-34.45%2C178.52&page=1&c=NZ" class="explore__item" v-html="trailsInText(i18n.txtExploreNewZealand)"></a>
          </div>
        
         
        </div>
        
        <div class="explore__footer"> <!-- Footer -->
          <a onclick="gtagEvent('goto_map_click', { ref: 'explore_more_countries'});" :href="'/' + i18n.txtUrlExploreMoreCountries" class="explore__more-countries">{{i18n.txtExploreMoreCountries}}&nbsp;<img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/arrow_right.svg"/></a>  
          <a onclick="gtagEvent('goto_map_click', { ref: 'explore_worldmap'});" href="/wikiloc/map.do?sw=-89.999%2C-179.999&ne=89.999%2C179.999&page=1" class="explore__worldmap"><span class="explore__worldmap-text">{{i18n.txtWorldmap}}</span><span class="explore__worldmap-icon"></span></a> 
        </div>
        
      </div>
    </div>
  </div>
</div>
`,
        methods: {
            open: open,
            close: close,
            onMainButtonClick: onMainButtonClick,
            onClickOutside: onClickOutside,
            trailsInText: trailsInText,
            readScreenDimensions: readScreenDimensions,
            readHeaderDimensions: readHeaderDimensions,
            readColumnMaxWidth: readColumnMaxWidth,
        },
        computed: {
            isResponsive: isResponsive,
            modalStyleCss: modalStyleCss,
        },
        watch: {},
        //VUE LIFECYCLE
        mounted: mounted,
        beforeDestroy: beforeDestroy,
    });

    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */

    const RESPONSIVE_WIDTH_THRESHOLD_PX = 768;

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */

    function onMainButtonClick() {
        gtagEvent("explore_click", { ref: "header" });
        fbq("trackCustom", "explore_click", { ref: "header" });
        if (!this.isModalOpen) {
            this.open();
        } else {
            this.close();
        }
    }

    function open() {
        this.isModalOpen = true;

        if (this.isResponsive) {
            this.readHeaderDimensions();
            _doubleRaf(() => {
                _enableDocumentScroll(false);
            });
        } else {
            this.$nextTick(() => {
                this.readColumnMaxWidth();
            });
        }
    }

    function close() {
        if (this.isResponsive) {
            _doubleRaf(() => {
                _enableDocumentScroll(true);
            });
        }

        this.isModalOpen = false;
    }

    function onClickOutside() {
        var component = this.$refs.modal;
        var componentButton = this.$refs.modalButton;
        //The click is in the component
        if (
            !component ||
            !componentButton ||
            component.contains(event.target) ||
            componentButton.contains(event.target)
        ) {
            return;
            //Hide
        } else {
            this.isModalOpen = false;
        }
    }

    function trailsInText(countryText) {
        return this.i18n.txtExploreTrailsIn.replace("{0}", countryText);
    }

    /**
     * We get the screen dimensions to use them as part of computed properties
     */
    function readScreenDimensions() {
        this.heightScreenPx = window.innerHeight;
        this.widthScreenPx = window.innerWidth;
    }

    function readHeaderDimensions() {
        this.$nextTick(() => {
            this.headerHeightPx = this.$refs.header.clientHeight;
        });
    }

    function readColumnMaxWidth() {
        var width = 0;

        if (this.isModalOpen) {
            width = this.$refs.column1.clientWidth;

            var columnWidth2 = this.$refs.column2.clientWidth;
            if (columnWidth2 > width) {
                width = columnWidth2;
            }
        }

        this.columnMaxWidthPx = width;
    }

    /**
     * ---- COMPUTED ATTRIBUTES ----
     * https://vuejs.org/v2/guide/computed.html#Computed-Properties
     */

    function isResponsive() {
        return this.widthScreenPx <= RESPONSIVE_WIDTH_THRESHOLD_PX;
    }

    /**
     * With flex we cannot process the width based on children space, we need to do it with js.
     */
    function modalStyleCss() {
        var styles = {};

        if (!this.isResponsive) {
            styles.width = this.columnMaxWidthPx * 2 + 64 + "px"; //paddings modal
        }

        return styles;
    }

    /**
     * ------------------
     * ---- WATCHERS ----
     * ------------------
     * When Vue reactivity is not enough
     * https://vuejs.org/v2/guide/computed.html#Watchers
     */

    /**
     * -----------------------
     * ---- VUE LIFECYCLE ----
     * -----------------------
     * https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
     */

    /**
     * Called after the instance has been mounted, where el is replaced by the newly created vm.$el.
     * If the root instance is mounted to an in-document element, vm.$el will also be in-document when mounted is called.
     *
     * Note that mounted does not guarantee that all child components have also been mounted.
     * If you want to wait until the entire view has been rendered, you can use vm.$nextTick inside of mounted
     */
    function mounted() {
        //1 - Native event Listeners
        document.addEventListener("mousedown", this.onClickOutside);

        //2 - Vue eventbus Listeners

        //3 - Init
        this.readScreenDimensions();

        //4 - After the DOM is mounted
        this.$nextTick(function () {
            window.addEventListener("resize", (event) => {
                this.readScreenDimensions();
            });
        });
    }

    function beforeDestroy() {
        //1 - Native event Listeners
        document.removeEventListener("mousedown", this.onClickOutside);

        //2 - Vue eventbus Listeners
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     * We need to do it in the first wrapper element on mobiles
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let overflow = enable ? "auto" : "hidden";

        //HTML tag
        document.documentElement.style.overflow = overflow;

        //BODY tag
        document.body.style.overflow = overflow;
    }

    /**
     * --------------
     * ---- DTOs ----
     * --------------
     *
     */

    ///////////////////////////////////////////////////////////////////////
    //A Vue Main (check for multiple instances)

    if (document.getElementsByClassName("component-explore").length > 0) {
        new Vue({
            el: ".component-explore", //link to id="search-box" DOM element
            data: {
                i18n: INJECT_HEADER.i18n,
                ignoreResponsiveIcon: INJECT_HEADER.exploreIgnoreResponsiveIcon
                    ? true
                    : false,
            },
            template: `
       <explore
          :i18n="i18n"
          :ignoreResponsiveIcon="ignoreResponsiveIcon">
        </explore>`,
        });
    }
})();
